span {
  font-family: 'Nippo';
}

.final {
  color: white;
  font-size: 28px;
}

.off-total {
  color: white;
}