.separator::after,
.separator::before {
  content: "";
  flex: 1;
}

.black::after,
.black::before{
  border-top: 1px solid black;
}

.white::after,
.white::before {
  border-top: 1px solid white;
}

.topic {
  display: flex;
  align-items: center;
  margin: 45px 0 15px;
}

.topic-name {
  margin-bottom: 0;
  padding: 1px 25px;
  font-weight: bold;
  font-size: .8rem;
}