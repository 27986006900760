.topics {
  color: white;
  font-size: 20px;
  margin-top: 12rem;
  margin-bottom: 3rem;
}

.topics-description {
  font-weight: 600;
}

.play {
  font-size: 20px;
  font-weight: 600;
  margin-top: 4rem;
  background-color: #9F47FC;
  border: none;
}

.game-owner {
  font-size: 14px;
  margin-top: 9rem;
  text-decoration-line: underline;
}